<template>
  <v-card>
    <v-form>
      <v-toolbar v-if="!editing" style="margin-bottom: 5px" elevation="1" flat>
        <v-toolbar-title>
          <span class="headline">{{ formTitle }}</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon large @click="$router.push('/listagem-produtos')"
          >mdi-arrow-left</v-icon
        >
      </v-toolbar>
      <v-toolbar v-if="editing" style="margin-bottom: 5px" elevation="1" flat>
        <v-toolbar-title>
          <span class="headline">{{ formTitle }}</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon large @click="handleClose(dialog, $event)">mdi-close</v-icon>
      </v-toolbar>
      <v-row no-gutters>
        <v-col cols="12" md="3" class="px-4 py-2 my-6">
          <div class="d-flex flex-wrap justify-space-between">
            <label
              v-if="!capaUrl"
              for="logo"
              style="cursor: pointer; width: 100%"
              class="caption d-flex justify-center flex-wrap border mr-4 px-2"
              title="Add logo cliente"
            >
              <v-icon
                x-large
                color="primary"
                style="height: 200px; width: 100%"
                for="logo"
                >mdi-cloud-upload</v-icon
              >
              <span class="mb-2"> Foto Principal </span>
              <input
                id="logo"
                style="display: none"
                type="file"
                @change="handleUpload('capa', $event)"
              />
            </label>
            <v-img
              v-else
              max-height="250"
              style="width: 15%"
              class="caption d-flex justify-center flex-wrap mr-4 px-2"
              :src="capaUrl"
            >
            </v-img>
            <div
              v-if="!editing && capaUrl"
              style="cursor: pointer"
              class="w-full d-flex justify-end pr-2 mt-2 hover-red"
              @click="capaUrl = ''"
            >
              <v-icon> mdi-delete </v-icon>
            </div>
            <div
              v-if="
                capaUrl &&
                editing &&
                checkIfPublicado &&
                !checkIfBloqueado &&
                !checkIfCancelado
              "
              style="cursor: pointer"
              class="w-full d-flex justify-end pr-2 mt-2 hover-red"
            >
              <label
                for="logo"
                style="cursor: pointer; width: 100%"
                class="caption d-flex justify-end flex-wrap px-2"
                title="Add logo cliente"
              >
                <v-icon> mdi-camera-retake-outline </v-icon>
                <input
                  id="logo"
                  style="display: none"
                  type="file"
                  @change="handleUpload('capa', $event)"
                />
              </label>
            </div>
          </div>
        </v-col>
        <v-col cols="9" md="9" class="px-4 my-3">
          <v-select
            v-if="!editing && !cupom"
            v-model="cliente"
            :items="clientes"
            label="Cliente"
          />
          <v-text-field v-if="editing" v-model="cliente" disabled />
          <v-text-field
            v-if="!cupom"
            v-model="nomeProduto"
            :rules="nomeProdutoRules"
            :disabled="
              !checkIfPublicado ||
              checkIfBloqueado ||
              checkIfCancelado ||
              checkIfEncerrado ||
              (checkIfLiberado && isAdminParceiro)
            "
            required
            label="Nome do Produto *"
          />
          <v-select
            v-if="cupom"
            v-model="nomeProduto"
            :items="produtosList"
            :disabled="
              !checkIfPublicado ||
              checkIfBloqueado ||
              checkIfCancelado ||
              checkIfEncerrado ||
              (checkIfLiberado && isAdminParceiro)
            "
            required
            label="Nome do Produto *"
          />
          <div class="d-flex flex-wrap">
            <v-select
              v-if="!editing && !cupom"
              v-model="categoria"
              :items="categorias"
              :rules="categoriaRules"
              :disabled="!checkIfPublicado"
              required
              label="Categoria *"
              style="width: 50%"
              class="pr-6"
            />
            <v-text-field
              v-if="editing"
              v-model="categoria"
              required
              :disabled="cupom || editing"
              label="Categoria *"
              style="width: 50%"
              class="pr-6"
            />
            <v-select
              v-if="!editing && !cupom"
              v-model="unidadeMedida"
              :items="medidas"
              :disabled="!checkIfPublicado"
              label="Unidade de medida"
              class="pr-3"
            />
            <v-text-field
              v-if="editing"
              v-model="unidadeMedida"
              disabled
              label="Unidade de medida"
              class="pr-3"
            />
            <v-text-field
              v-model="codigoFornecedor"
              :disabled="
                !checkIfPublicado ||
                checkIfBloqueado ||
                checkIfCancelado ||
                checkIfEncerrado ||
                (checkIfLiberado && isAdminParceiro)
              "
              label="Código Auxiliar"
              class="pr-3"
            />
            <v-text-field
              v-if="editing"
              v-model="referencia"
              disabled
              label="Número de Referencia"
              class="pr-4"
            />
            <v-select
              v-if="cupom"
              v-model="situacaoCupom"
              disabled
              label="Situação"
            />
            <v-text-field
              v-if="editCupom || editing"
              v-model="situacaoCupom"
              disabled
              label="Situação"
            />
          </div>
          <v-text-field
            v-if="cupom"
            v-model="especificacaoCupom"
            :rules="especificacaoRules"
            :disabled="!checkIfPublicado"
            required
            label="Nome do Cupom *"
          />
        </v-col>
        <v-col cols="12" md="12" class="my-3 px-4">
          <v-textarea
            v-model="especificacoes"
            :rules="especificacaoRules"
            :disabled="
              !checkIfPublicado ||
              checkIfBloqueado ||
              checkIfCancelado ||
              checkIfEncerrado ||
              (checkIfLiberado && isAdminParceiro)
            "
            required
            :label="especificacao"
          />
          <v-row class="px-3 my-2">
            <v-select
              v-if="cupom || editCupom"
              v-model="categoriaPagamento"
              :disabled="
                !checkIfPublicado ||
                checkIfBloqueado ||
                checkIfCancelado ||
                checkIfEncerrado ||
                (checkIfLiberado && isAdminParceiro)
              "
              :items="tiposDePagamento"
              class="mr-2"
              required
              label="Categoria de pagamento *"
            />
            <v-select
              v-if="hasParceiro"
              v-model="parceiro"
              :disabled="
                !checkIfPublicado ||
                checkIfBloqueado ||
                checkIfCancelado ||
                checkIfEncerrado ||
                (checkIfLiberado && isAdminParceiro)
              "
              :items="parceiros"
              class="mr-2"
              required
              label="Parceiro"
            />
            <v-switch
              v-if="(cupom || editCupom) && hasParceiro"
              v-model="codeUnico"
              inset
              :disabled="
                !checkIfPublicado ||
                checkIfBloqueado ||
                checkIfCancelado ||
                checkIfEncerrado ||
                (checkIfLiberado && isVendedor)
              "
              class="pr-3"
              label="Código Único"
            ></v-switch>
            <v-switch
              v-if="cupom || editCupom"
              v-model="userEnds"
              inset
              :disabled="
                !checkIfPublicado ||
                checkIfBloqueado ||
                checkIfCancelado ||
                checkIfEncerrado ||
                (checkIfLiberado && isAdminParceiro)
              "
              class="pr-3"
              label="Encerramento pelo Estabelecimento"
            ></v-switch>
          </v-row>
          <div v-if="cupom || editCupom" class="d-flex flex-wrap">
            <v-select
              v-if="cupom || editCupom"
              v-model="tipoPromocao"
              :rules="tipoPromocaoRules"
              :disabled="
                !checkIfPublicado ||
                checkIfBloqueado ||
                checkIfCancelado ||
                checkIfEncerrado ||
                (checkIfLiberado && isAdminParceiro) ||
                categoriaDisabler
              "
              required
              :items="promocao"
              label="Tipo de Promoção *"
              style="width: 50%"
              class="pr-6"
            />
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="dataInicio"
              :disabled="
                !checkIfPublicado ||
                checkIfBloqueado ||
                checkIfCancelado ||
                checkIfEncerrado ||
                (checkIfLiberado && isAdminParceiro)
              "
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="formatedDataInicio"
                  label="Data de Início"
                  prepend-icon="mdi-calendar"
                  :disabled="
                    !checkIfPublicado ||
                    checkIfBloqueado ||
                    checkIfCancelado ||
                    checkIfEncerrado ||
                    (checkIfLiberado && isAdminParceiro)
                  "
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dataInicio"
                no-title
                locale="pt-BR"
                :allowed-dates="allowedDateInicio"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Cancelar
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(dataInicio)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
            <v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              :return-value.sync="dateTermino"
              :disabled="
                !checkIfPublicado ||
                checkIfBloqueado ||
                checkIfCancelado ||
                checkIfEncerrado ||
                (checkIfLiberado && isAdminParceiro)
              "
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="formatedDataFinal"
                  label="Data de Termino"
                  prepend-icon="mdi-calendar"
                  :disabled="
                    !checkIfPublicado ||
                    checkIfBloqueado ||
                    checkIfCancelado ||
                    checkIfEncerrado ||
                    (checkIfLiberado && isAdminParceiro)
                  "
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  class="mr-2"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateTermino"
                :allowed-dates="allowedDateTermino"
                no-title
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu2 = false">
                  Cancelar
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu2.save(dateTermino)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
            <v-text-field
              v-if="checkTipoDesconto && (cupom || editCupom)"
              v-model="quantity"
              type="number"
              label="Quantidade"
              :disabled="
                !checkIfPublicado ||
                checkIfBloqueado ||
                checkIfCancelado ||
                checkIfEncerrado ||
                (checkIfLiberado && isAdminParceiro)
              "
              class="pr-3"
              :required="isTipoPromocaoLimitado"
            />
            <v-switch
              v-if="checkIfLiberado || !checkIfPublicado"
              v-model="isPremium"
              inset
              :disabled="
                !checkIfPublicado ||
                checkIfBloqueado ||
                checkIfCancelado ||
                checkIfEncerrado ||
                (checkIfLiberado && isVendedor) ||
                isUsuarioParceiro ||
                isAdminParceiro
              "
              class="pr-3"
              label="Cupom Premium"
            ></v-switch>
          </div>
          <div v-if="tipoPromocao !== 4" class="d-flex flex-wrap">
            <vuetify-money
              v-model="precoTotal"
              required
              :disabled="
                !checkIfPublicado ||
                checkIfBloqueado ||
                checkIfCancelado ||
                checkIfEncerrado ||
                (checkIfLiberado && isAdminParceiro)
              "
              label="Preço Total do Produto *"
              class="pr-6"
            />
            <div class="d-flex pr-3" style="width: 33%">
              <v-switch
                v-model="hasDiscount"
                inset
                :disabled="
                  !checkIfPublicado ||
                  checkIfBloqueado ||
                  checkIfCancelado ||
                  checkIfEncerrado ||
                  (checkIfLiberado && isAdminParceiro)
                "
                class="pr-3"
                :label="gotDiscount"
              ></v-switch>
              <vuetify-money
                v-if="hasDiscount"
                v-model="precoDesconto"
                :disabled="
                  !checkIfPublicado ||
                  checkIfBloqueado ||
                  checkIfCancelado ||
                  checkIfEncerrado ||
                  (checkIfLiberado && isAdminParceiro)
                "
                :required="hasPromotion"
                class="mr-2"
                label="Valor do Desconto"
              />
              <vuetify-money
                v-if="(cupom || editCupom) && categoriaPagamento == 2"
                v-model="precoTaxa"
                label="Taxa"
                class="pr-4"
                disabled
              />
            </div>
            <vuetify-money
              :value="precoVenda"
              disabled
              label="Preço de Venda"
              class="mr-4"
            />
          </div>
          <div v-if="tipoPromocao == 4" class="d-flex flex-wrap">
            <vuetify-money
              v-model="percentual"
              :options="percent"
              :disabled="
                !checkIfPublicado ||
                checkIfBloqueado ||
                checkIfCancelado ||
                checkIfEncerrado ||
                (checkIfLiberado && isAdminParceiro)
              "
              label="Percentual"
            />
          </div>
        </v-col>
        <v-col v-if="cupom || editCupom" cols="12" md="12" class="my-3 px-4">
          <div class="d-flex">
            <v-switch
              v-if="hasEvento"
              v-model="clientHasEvento"
              inset
              :disabled="
                !checkIfPublicado ||
                checkIfCancelado ||
                checkIfBloqueado ||
                (checkIfLiberado && isAdminParceiro)
              "
              label="Incluir em Evento"
            ></v-switch>
            <v-select
              v-if="clientHasEvento"
              v-model="evento"
              :items="eventos"
              :disabled="
                !checkIfPublicado ||
                checkIfCancelado ||
                checkIfBloqueado ||
                (checkIfLiberado && isAdminParceiro)
              "
              label="Eventos"
              class="pl-4"
            />
          </div>
        </v-col>
        <v-col v-if="cupom || editCupom" cols="3" md="3" class="my-3 px-4">
          <v-switch
            v-model="timeline"
            inset
            :disabled="
              !checkIfPublicado ||
              checkIfCancelado ||
              checkIfBloqueado ||
              (checkIfLiberado && isAdminParceiro)
            "
            label="Incluir na Timeline"
          ></v-switch>
        </v-col>
        <v-col v-if="timeline" cols="9" md="9" class="my-3 px-4">
          <div class="d-flex"></div>
          <v-select
            v-model="assunto"
            :items="assuntos"
            :disabled="
              !checkIfPublicado ||
              checkIfBloqueado ||
              checkIfCancelado ||
              checkIfEncerrado ||
              (checkIfLiberado && isAdminParceiro)
            "
            label="Assunto"
          />
        </v-col>
        <v-col cols="12" md="12" class="my-3 px-4 d-flex justify-end">
          <v-btn
            v-if="editCupom"
            color="primary"
            class="mr-5"
            @click="modalCopiarDesconto = true"
          >
            Copiar
          </v-btn>
          <v-btn
            v-if="editCupom && hasParceiro && !codeUnico"
            color="primary"
            :disabled="
              (!checkIfCadastrado && !checkIfLiberado && checkIfCancelado) ||
              checkIfEncerrado
            "
            class="mr-5"
            @click="openCode()"
          >
            Códigos
          </v-btn>
          <v-btn
            v-if="editing && !editCupom"
            color="primary"
            :disabled="
              (!checkIfCadastrado && !checkIfLiberado && checkIfCancelado) ||
              checkIfEncerrado
            "
            class="mr-5"
            @click="confirmacaoProduto = true"
          >
            Cancelar
          </v-btn>
          <v-btn
            v-if="editCupom"
            color="primary"
            :disabled="!checkIfCadastrado && !checkIfLiberado"
            class="mr-5"
            @click="confirmacaoDescontoCancelar = true"
          >
            Cancelar
          </v-btn>
          <v-btn
            v-if="editCupom && editing"
            color="primary"
            :disabled="checkIfPublicado"
            class="mr-5"
            @click="confirmacaoDescontoBloquear = true"
          >
            Bloquear
          </v-btn>
          <v-btn
            v-if="editCupom && editing"
            color="primary"
            :disabled="!checkIfCadastrado"
            class="mr-5"
            @click="submitEditDesconto(2)"
          >
            Liberar
          </v-btn>
          <v-btn
            v-if="editCupom && editing && isRoot"
            class="mr-5"
            :disabled="!checkIfLiberado"
            color="primary"
            @click="confirmacaoPublicar = true"
          >
            Publicar
          </v-btn>
          <v-btn
            v-if="cupom"
            color="primary"
            class="mr-5"
            @click="submitDesconto()"
          >
            Salvar
          </v-btn>
          <v-btn
            v-else-if="
              editing && !editCupom && !createProduct && !checkIfCancelado
            "
            color="primary"
            class="mr-5"
            @click="submitEdit(1)"
          >
            Salvar
          </v-btn>
          <v-btn
            v-if="editCupom && editing && !createProduct && !checkIfCancelado"
            color="primary"
            :disabled="
              (checkIfLiberado && isAdminParceiro) ||
              checkIfBloqueado ||
              checkIfCancelado ||
              !checkIfPublicado ||
              checkIfEncerrado
            "
            class="mr-5"
            @click="submitEditDesconto(1)"
          >
            Salvar
          </v-btn>
          <v-btn
            v-if="isProduto"
            color="primary"
            class="mr-5"
            @click="submitForm(1)"
          >
            Salvar
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-dialog v-model="confirmacaoPublicar" persistent max-width="460">
      <v-card>
        <v-card-title class="text-h5">
          Deseja Realmente Prosseguir?
        </v-card-title>
        <v-card-text>
          O desconto será disponibilizado para os usuários. Este procedimento
          bloqueará futuras alterações.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="confirmacaoPublicar = false"> Voltar </v-btn>
          <v-btn color="error" text @click="submitEditDesconto(5)">
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modalCopiarDesconto" persistent max-width="460">
      <v-card>
        <v-card-title class="text-h5"> Copiar Cupom </v-card-title>
        <v-card-text> Deseja copiar o desconto indicado? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="modalCopiarDesconto = false"> Voltar </v-btn>
          <v-btn
            color="error"
            :loading="copyLoading"
            text
            @click="copiarDesconto()"
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirmacaoProduto" persistent max-width="460">
      <v-card>
        <v-card-title class="text-h5">
          Deseja Realmente Prosseguir?
        </v-card-title>
        <v-card-text
          >O Produto a seguir não estará mais disponível e a ação é
          irreversível.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="confirmacaoProduto = false"> Desistir </v-btn>
          <v-btn color="error" text @click="submitEdit(4)"> Continuar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirmacaoDescontoBloquear" persistent max-width="460">
      <v-card>
        <v-card-title class="text-h5">
          Deseja Realmente Prosseguir?
        </v-card-title>
        <v-card-text
          >O Desconto a seguir não estará mais disponível e a ação é
          irreversível.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="confirmacaoDescontoBloquear = false">
            Desistir
          </v-btn>
          <v-btn color="error" text @click="submitEditDesconto(3)">
            Continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirmacaoDescontoCancelar" persistent max-width="460">
      <v-card>
        <v-card-title class="text-h5">
          Deseja Realmente Prosseguir?
        </v-card-title>
        <v-card-text
          >O Desconto a seguir não estará mais disponível e a ação é
          irreversível.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="confirmacaoDescontoCancelar = false">
            Desistir
          </v-btn>
          <v-btn color="error" text @click="submitEditDesconto(4)">
            Continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <modulo-codigos
      v-if="codeDialog"
      :key="counter"
      :partner="parceiro.text"
      :id="produtoId"
      :situacao="situacaoId"
      :quantity="quantity"
      :finalDate="produtos.dataValidade"
      @close="codeDialog = false"
    />
    <modulo-usuarios
      v-if="dialogUsuarios"
      :id="produtoId"
      @close="codeDialog = false"
    />
  </v-card>
</template>

<script>
import {
  ProdutoService,
  TiposService,
  InstalacaoService,
} from "../../../services";
import { Role } from "../../../constants";
import moduloCodigos from "../componentes/moduloCodigosParceiros.vue";
import moduloUsuarios from "../componentes/moduloUsuarios.vue";
import moment from "moment";

export default {
  name: "formularioProduto",

  components: {
    moduloCodigos,
    moduloUsuarios,
  },

  props: {
    produtos: {
      type: Object,
    },
    isProduto: {
      type: Boolean,
      default: false,
    },
    cupom: {
      type: Boolean,
      default: false,
    },
    editing: {
      type: Boolean,
      default: false,
    },
    editCupom: {
      type: Boolean,
      default: false,
    },
    createProduct: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      assuntos: [],
      copyLoading: false,
      codeDialog: false,
      assunto: 0,
      status: ["Ativo", "Desativado", "Inválido"],
      dataInicio: moment(new Date()).format("Y-MM-DD"),
      dateTermino: null,
      menu2: false,
      counter: 0,
      dataToFormat: null,
      quantity: null,
      modalCopiarDesconto: false,
      parceiros: [],
      codigoFornecedor: "",
      codeUnico: false,
      confirmacaoPublicar: false,
      especificacaoCupom: "",
      dataEvento: "",
      useFotoProduto: false,
      promocao: [],
      menu: false,
      medidas: [],
      unidadeMedida: null,
      confirmacaoProduto: false,
      confirmacaoDescontoBloquear: false,
      confirmacaoDescontoCancelar: false,
      fotos: null,
      hasDiscount: false,
      cliente: "",
      clientes: [],
      nomeProduto: "",
      situacaoCupom: { text: "Em Cadastramento", value: 1 },
      situacoesCupom: [
        { text: "Em Cadastramento", value: 1 },
        { text: "Liberar", value: 2 },
        { text: "Bloqueado", value: 3 },
        { text: "Cancelado", value: 4 },
        { text: "Publicado", value: 5 },
      ],
      dataCriacao: "23/10/2021",
      classificacao: "",
      categorias: null,
      timeline: false,
      hasNotify: false,
      nomeProdutoRules: [(v) => !!v || "O Nome é obrigatório"],
      categoriaRules: [(v) => !!v || "A Categoria é obrigatória"],
      especificacaoRules: [(v) => !!v || "A Especificação é obrigatória"],
      tipoPromocaoRules: [(v) => !!v || "O tipo de promoção é obrigatório"],
      descricaoRules: [(v) => !!v || "O Nome do Cupom é obrigatória"],
      quantidadeRules: [(v) => !!v || "A quantidade é obrigatória"],
      categoria: "",
      tiposDePagamento: [],
      referencia: "",
      especificacoes: "",
      capaUrl: null,
      capaTpMidia: null,
      tipoPromocao: "",
      situacao: "",
      precoTotal: 0,
      precoDesconto: 0,
      dialog: false,
      payload: {},
      file: {},
      formData: {},
      produtoId: null,
      produtosList: [],
      saveProduto: null,
      editInfo: {},
      percentual: null,
      percent: {
        locale: "pt-BR",
        prefix: "",
        suffix: "%",
        length: 4,
        precision: 2,
      },
      promoAberta: null,
      planoId: null,
      clienteEventoId: null,
      hasEvento: false,
      categoriaPagamento: null,
      parceiro: null,
      userEnds: true,
      clientHasEvento: false,
      eventos: null,
      evento: null,
      dialogUsuarios: false,
      categoriaDisabler: false,
      isPremium: false,
      taxaMultiplicador: 0,
      situacaoId: 0,
    };
  },
  created() {
    this.getFiltros();
    this.getMedida();
    this.getAssuntos();
    this.getTipoPromocao();
    this.getTaxaMultiplicador();
    if (this.cupom || this.editCupom) {
      this.getProdutos();
      this.tiposPagamento();
      this.getParceiros();
    }
  },
  mounted() {
    if (window.location.pathname === "/listagem-produtos") {
      this.editInfo = { ...this.produtos };
      this.capaId = this.produtos?.produtoImagem
        ? this.produtos?.produtoImagem[0].id
        : null;
      this.cliente = this.produtos?.clienteNome;
      this.referencia = this.produtos?.codigo;
      this.codigoFornecedor = this.produtos?.codigoFornecedor;
      this.capaUrl = this.produtos?.produtoImagem
        ? this.produtos?.produtoImagem[0].midiaUrl
        : null;
      this.especificacoes = this.produtos?.produtoDescricao;
      this.nomeProduto = this.produtos?.descricao;
      this.unidadeMedida = this.produtos?.sigla;
      this.parceiro = this.produtos?.parceiroId;
      this.categoriaPagamento = this.produtos?.tipoPagamentoProdutoId;
      this.userEnds = this.produtos?.isEncerrraUsuario ? false : true;
      this.categoria = this.produtos?.categoriaTitulo;
      this.codeUnico = this.produtos?.isCodigoUnico;
      this.situacaoCupom =
        this.editInfo.situacaoId === 3 ? "Bloqueado" : this.produtos?.situacao;
      this.situacaoId = this.produtos?.situacaoId;
      this.dataCriacao = moment(this.produtos?.dataCadastro).format(
        "D/M/Y [às] h:mm A z"
      );
      this.dataInicio = moment(this.produtos?.dataInicio).format("Y-MM-DD");
      this.dateTermino = moment(this.produtos?.dataValidade).format("Y-MM-DD");
      this.tipoPromocao = this.editInfo.tipoDescontoId;
      this.precoDesconto = this.produtos?.valorDesconto;
      this.precoVenda = this.produtos?.valor;
      this.precoTotal = this.produtos?.valorTotal;
      this.hasDiscount = this.produtos?.valorDesconto ? true : false;
      this.produtoId = this.produtos?.id;
      this.percentual = this.produtos?.percentualDesconto;
      this.quantity = this.produtos?.quantidade;
      this.timeline = this.produtos?.assuntoId ? true : false;
      this.assunto = this.produtos?.assuntoId ? this.produtos?.assuntoId : null;
      this.hasEvento = this.produtos?.eventoId ? true : false;
      this.clientHasEvento = this.produtos?.eventoId ? true : false;
      this.evento = this.produtos?.eventoId;
      this.isPremium = this.produtos?.isAssinante;
      if (this.editCupom) {
        this.getUserEventos(this.produtos?.clienteId);
      }
      if (!this.produtos.codeUnico && this.produtos.categoriaPagamento == 3) {
        this.promocao = this.promocao.filter((f) => f.value == 3);
      } else {
        this.getTipoPromocao();
      }
    }
  },
  watch: {
    hasDiscount() {
      if (this.hasDiscount == false) {
        this.precoDesconto = 0;
      }
    },
    nomeProduto() {
      this.filterClients();
    },
    categoriaPagamento() {
      if (this.categoriaPagamento == 2) {
        return (this.promocao = this.promocao.filter((f) => f.value !== 4));
      }
      if (this.categoriaPagamento == 3 && !this.codeUnico) {
        this.promocao = this.promocao.filter((f) => f.value == 3);
        this.tipoPromocao = 3;
      } else {
        this.getTipoPromocao();
      }
    },
    codeUnico() {
      if (!this.codeUnico && this.categoriaPagamento == 3) {
        this.promocao = this.promocao.filter((f) => f.value == 3);
      } else {
        this.getTipoPromocao();
      }
    },
  },
  computed: {
    hasParceiro() {
      return this.categoriaPagamento === 3 ? true : false;
    },

    formatedDataInicio() {
      return this.formatDate(this.dataInicio);
    },

    isRoot() {
      return this.$store.getters["user/getUser"].roleId == Role.ROOT
        ? true
        : false;
    },
    isAdminParceiro() {
      return this.$store.getters["user/getUser"].roleId == Role.ADMIN_PARCEIRO
        ? true
        : false;
    },
    isUsuarioParceiro() {
      return this.$store.getters["user/getUser"].roleId == Role.USER_PARCEIRO
        ? true
        : false;
    },
    formatedDataFinal() {
      return this.formatDate(this.dateTermino);
    },
    checkTipoDesconto() {
      return this.tipoPromocao === 2
        ? false
        : this.tipoPromocao === 4
        ? false
        : this.tipoPromocao === 1
        ? false
        : true;
    },
    checkIfCadastrado() {
      return this.editInfo.situacaoId === 1 ? true : false;
    },
    checkIfEncerrado() {
      return this.editInfo.situacaoId === 9 ? true : false;
    },
    checkSituacaoLiberarCadastrar() {
      return this.editInfo.situacaoId !== 1 || this.editInfo.situacaoId !== 2
        ? true
        : false;
    },
    checkIfBloqueado() {
      return this.editInfo.situacaoId === 3 ? true : false;
    },
    checkIfCancelado() {
      return this.editInfo.situacaoId === 4 ? true : false;
    },
    checkIfLiberado() {
      return this.editInfo.situacaoId === 2 ? true : false;
    },
    checkIfPublicado() {
      return this.editInfo.situacaoId !== 5 ? true : false;
    },
    validated() {
      if (
        this.cliente &&
        this.nomeProduto &&
        this.categoria &&
        this.unidadeMedida &&
        this.especificacao &&
        this.capaUrl
      ) {
        return true;
      } else {
        return false;
      }
    },
    validatedCupom() {
      if (
        this.especificacaoCupom &&
        this.unidadeMedida &&
        this.especificacao &&
        this.capaUrl
      ) {
        return true;
      } else {
        return false;
      }
    },
    checkRole() {
      const user = this.$store.state.user.user.roleLabel;
      if (user === "Root") {
        return false;
      } else {
        return true;
      }
    },
    especificacao() {
      if (this.cupom) {
        return "Condição *";
      } else {
        return "Especificação *";
      }
    },
    gotDiscount() {
      if (this.hasDiscount === true) {
        return "";
      } else {
        return "O produto possui desconto?";
      }
    },
    formTitle() {
      if (window.location.pathname === "/cadastrar-produtos") {
        return "Cadastrar novo Produto";
      } else if (window.location.pathname === "/cadastrar-cupom") {
        return "Cadastrar Novo Desconto/Cupom";
      } else {
        return "Editar";
      }
    },
    hasPromotion() {
      if (this.tipoPromocao === "Simples") {
        return true;
      } else {
        return false;
      }
    },
    precoVenda() {
      return this.precoTotal - this.precoDesconto;
    },
    precoTaxa() {
      return (this.precoTotal - this.precoDesconto) * this.taxaMultiplicador;
    },
    isTipoPromocaoLimitado() {
      return this.tipoPromocao == 3;
    },
  },
  methods: {
    openCode() {
      this.counter += 1;
      this.codeDialog = true;
    },

    getTaxaMultiplicador() {
      InstalacaoService.getAtual()
        .then((response) => {
          this.taxaMultiplicador = response.data.percTaxaConveniencia / 100;
        })
        .catch((error) => {
          if (error.status != 401) {
            this.$root.showErro(error.data);
          }
        });
    },
    filterClients() {
      let filterClients = this.produtosList.filter(
        (f) => f.value === this.nomeProduto
      );
      this.clienteEventoId = filterClients.pop().clienteId;
      this.getUserEventos(this.clienteEventoId);
    },
    getParceiros() {
      TiposService.getParceiros()
        .then((response) => {
          this.parceiros = response.data.map((p) => ({
            text: p.descricao,
            value: p.id,
          }));
        })
        .catch((error) => {
          if (error.status != 401) {
            this.$root.showErro(error.data);
          }
        })
        .finally(() => {
          this.loadingSalvar = false;
        });
    },
    tiposPagamento() {
      TiposService.getTiposPagamento()
        .then((response) => {
          this.tiposDePagamento = response.data.map((p) => ({
            text: p.descricao,
            value: p.id,
          }));
        })
        .catch((error) => {
          if (error.status != 401) {
            this.$root.showErro(error.data);
          }
        })
        .finally(() => {
          this.loadingSalvar = false;
        });
    },
    getUserEventos(id) {
      ProdutoService.getEvento(id)
        .then((response) => {
          if (response.data.length > 0) {
            this.hasEvento = true;
            this.eventos = response.data.map((evento) => ({
              text: evento.descricao,
              value: evento.id,
            }));
          }
        })
        .catch((error) => {
          if (error.status != 401) {
            this.$root.showErro(error.data);
          }
        })
        .finally(() => {
          this.loadingSalvar = false;
        });
    },
    allowedDateInicio(val) {
      let dataInicial = moment(new Date()).format("Y-MM-DD");
      return val >= dataInicial;
    },

    allowedDateTermino(val) {
      let dataInicial = moment(new Date()).format("Y-MM-DD");
      return val > dataInicial;
    },
    handleClose() {
      this.$emit("onClose");
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    filterNull(obj) {
      return obj !== null;
    },
    deleteCapa() {
      ProdutoService.deleteCapa(this.produtoId)
        .then((response) => {
          response
            ? this.$root.showSucesso("Operação realizada com sucesso")
            : "";
          this.capaUrl = "";
        })
        .catch((error) => {
          if (error.status != 401) {
            this.$root.showErro(error.data);
          }
        })
        .finally(() => {
          this.loadingSalvar = false;
        });
    },
    async getProdutos() {
      try {
        const res = await ProdutoService.getProducts();
        this.saveProduto = res.data.content;
        let produtoSemCupom = res.data.content.filter(
          (p) => p.produtoTipo === 1
        );
        let produtoNaoCancelado = produtoSemCupom.filter(
          (p) => p.situacaoId !== 4
        );
        this.produtosList = produtoNaoCancelado.map((produto) => ({
          text: `${produto.descricao} - ${
            produto.clienteNome
          } - Valor: R$${this.asMoney(produto.valor)}`,
          value: produto.id,
          capa: produto.produtoImagem,
          planoId: produto.planoId,
          ...(produto.clienteId && { clienteId: produto.clienteId }),
        }));
      } catch (err) {
        console.log(err);
      }
    },
    async getFiltros() {
      try {
        const res = await ProdutoService.getFiltros();
        this.clientes = res.data.estabelecimentos.map((estabelecimentos) => ({
          text: estabelecimentos.descricao,
          value: estabelecimentos.id,
        }));
        let allCategorias = res.data.categorias.content.filter(this.filterNull);
        let filteredCategorias = allCategorias.filter((f) => f.planoId < 200);
        this.categorias = filteredCategorias.map((categoria) => ({
          text: categoria.categoriaTitle,
          value: categoria.categoriaId,
          planoId: categoria.planoId,
        }));
      } catch (error) {
        this.$root.showErro(error.data);
      }
    },
    submitEditDesconto(type) {
      if (this.timeline) {
        if (!this.assunto) {
          return this.$root.showErro("Informe um assunto");
        }
      }
      if (this.precoDesconto * 100 > this.precoTotal * 100) {
        return this.$root.showErro(
          "O valor do desconto não pode ser superior ao valor total"
        );
      }
      if (this.categoriaPagamento == 3 && !this.parceiro) {
        return this.$root.showErro("Selecione um Parceiro");
      }
      if (this.codeUnico == true && this.codigoFornecedor.length <= 0) {
        return this.$root.showErro("Informe um código auxiliar");
      }
      if (this.isTipoPromocaoLimitado && this.quantity < 1) {
        this.$root.showErro("Informe uma quantidade válida");
        return;
      }
      if (this.tipoPromocao !== 4) {
        if (this.precoTotal - this.precoDesconto <= 0) {
          this.$root.showErro("O valor de venda deve ser maior que zero");
          return;
        }
      }
      if (this.tipoPromocao === 4) {
        if (this.percentual <= 0) {
          this.$root.showErro("O percentual deve ser maior que zero");
          return;
        }
      }
      const payload = {
        assuntoId: this.assunto,
        isTimeline: this.timeline,
        codigoFornecedor: this.codigoFornecedor,
        isForaUso: false,
        planoId: 201,
        produtoDescricao: this.especificacoes,
        isEncerraUsuario: this.userEnds == true ? false : true,
        tipoPagamentoProdutoId: this.categoriaPagamento,
        parceiroId: this.parceiro,
        descricao: this.nomeProduto,
        dataInicio: this.dataInicio,
        dataValidade: this.dateTermino,
        id: this.editInfo.id,
        produtoImagem: [
          {
            id: 0,
            isCapa: true,
            isMapa: false,
            legenda: "string",
            midiaUrl: this.capaUrl,
            produtoId: this.editInfo.id,
            tpMidia: this.capaTpMidia,
          },
        ],
        produtoSuperiorId: this.editInfo.produtoSuperiorId,
        quantidade: this.tipoPromocao === 3 ? this.quantity : 0,
        tipoDesconto: this.tipoPromocao,
        situacaoId: type === 1 ? this.editInfo.situacaoId : type,
        percentualDesconto: this.percentual,
        unidadeMedidaId: this.editInfo.unidadeMedidaId,
        valor: this.tipoPromocao === 4 ? 0.0 : this.precoVenda,
        valorDesconto: this.tipoPromocao === 4 ? 0.0 : this.precoDesconto,
        valorTotal: this.tipoPromocao === 4 ? 0.0 : this.precoTotal,
        valorTaxaConveniencia: this.precoTaxa,
        eventoId: this.evento,
        isCodigoUnico: this.codeUnico,
        isAssinante: this.isPremium,
      };
      if (
        this.especificacoes &&
        this.dataInicio &&
        this.nomeProduto &&
        this.dateTermino &&
        this.tipoPromocao &&
        this.capaUrl
      ) {
        ProdutoService.salvarDesconto(payload)
          .then((response) => {
            this.$root.showSucesso("Operação realizada com sucesso");
            let a = 0;
            this.confirmacaoDescontoCancelar = false;
            this.confirmacaoDescontoBloquear = false;
            this.confirmacaoPublicar = false;
            this.$emit("submit", a++);
            console.log(response);
            if (type === 2) {
              this.situacaoCupom = "Liberado";
              this.editInfo.situacaoId = 2;
              this.situacaoId = 2;
            }
            if (type === 3) {
              this.situacaoCupom = "Bloqueado";
              this.editInfo.situacaoId = 3;
              this.situacaoId = 3;
            }
            if (type === 4) {
              this.situacaoCupom = "Cancelado";
              this.editInfo.situacaoId = 4;
              this.situacaoId = 4;
            }
            if (type === 5) {
              this.situacaoCupom = "Publicado";
              this.editInfo.situacaoId = 5;
              this.situacaoId = 5;
            }
          })
          .catch((error) => {
            if (error.status != 401) {
              this.$root.showErro(error.data);
            }
          })
          .finally(() => {
            this.loadingSalvar = false;
          });
      } else {
        this.$root.showErro("Preencha todos os campos obrigatórios");
      }
    },
    submitDesconto(type) {
      if (this.timeline) {
        if (!this.assunto) {
          return this.$root.showErro("Informe um assunto");
        }
      }
      if (this.precoDesconto * 100 > this.precoTotal * 100) {
        return this.$root.showErro(
          "O valor do desconto não pode ser superior ao valor total"
        );
      }
      if (this.categoriaPagamento == 3 && !this.parceiro) {
        return this.$root.showErro("Selecione um Parceiro");
      }
      if (this.isTipoPromocaoLimitado && this.quantity < 1) {
        this.$root.showErro("Informe uma quantidade válida");
        return;
      }
      if (this.tipoPromocao !== 4) {
        if (this.precoTotal - this.precoDesconto <= 0) {
          this.$root.showErro("O valor de venda deve ser maior que zero");
          return;
        }
      }
      if (this.tipoPromocao === 4) {
        if (this.percentual <= 0) {
          this.$root.showErro("O percentual deve ser maior que zero");
          return;
        }
      }
      const payload = {
        assuntoId: this.assunto,
        isTimeline: this.timeline,
        codigoFornecedor: this.codigoFornecedor,
        isForaUso: false,
        isEncerraUsuario: this.userEnds == true ? false : true,
        tipoPagamentoProdutoId: this.categoriaPagamento,
        parceiroId: this.parceiro,
        planoId: 201,
        produtoDescricao: this.especificacoes,
        descricao: this.especificacaoCupom,
        produtoImagem: [
          {
            isCapa: true,
            isMapa: false,
            legenda: "string",
            midiaUrl: this.capaUrl,
            produtoId: this.nomeProduto,
            tpMidia: this.capaTpMidia,
          },
        ],
        dataInicio: this.dataInicio,
        dataValidade: this.dateTermino,
        valorTaxaConveniencia: this.precoTaxa,
        produtoSuperiorId: this.nomeProduto,
        quantidade: this.quantity,
        tipoDesconto: this.tipoPromocao,
        situacaoId: type,
        unidadeMedidaId: this.unidadeMedida,
        percentualDesconto: this.percentual,
        isCodigoUnico: this.codeUnico,
        valor: this.tipoPromocao === 4 ? 0.0 : this.precoVenda,
        valorDesconto: this.tipoPromocao === 4 ? 0.0 : this.precoDesconto,
        valorTotal: this.tipoPromocao === 4 ? 0.0 : this.precoTotal,
        eventoId: this.evento,
      };
      if (
        this.especificacaoCupom &&
        this.especificacoes &&
        this.dataInicio &&
        this.nomeProduto &&
        this.dateTermino &&
        this.tipoPromocao &&
        this.capaUrl
      ) {
        ProdutoService.salvarDesconto(payload)
          .then((response) => {
            this.$root.showSucesso("Operação realizada com sucesso");
            console.log(response);
            this.$router.push("/listagem-produtos");
          })
          .catch((error) => {
            if (error.status != 401) {
              this.$root.showErro(error.data);
            }
          })
          .finally(() => {
            this.loadingSalvar = false;
          });
      } else {
        this.$root.showErro("Preencha todos os campos obrigatórios");
      }
    },
    submitEdit(type) {
      if (type === 2) {
        this.situacaoCupom = "Liberado";
        this.editInfo.situacaoId = 2;
      }
      if (type === 3) {
        this.situacaoCupom = "Bloqueado";
        this.editInfo.situacaoId = 3;
      }
      if (type === 4) {
        this.situacaoCupom = "Cancelado";
        this.editInfo.situacaoId = 4;
      }
      if (type === 5) {
        this.situacaoCupom = "Publicado";
        this.editInfo.situacaoId = 5;
      }
      if (this.isTipoPromocaoLimitado && this.quantity == null) {
        this.$root.showErro("Informe uma quantidade válida");
        return;
      }
      if (this.precoTotal - this.precoDesconto < 0) {
        this.$root.showErro("O valor de venda não pode ser inferior a zero");
        return;
      }
      const payload = {
        assuntoId: 0,
        categoriaId: this.editInfo.categoriaId,
        clienteId: this.editInfo.clienteId,
        descricao: this.nomeProduto,
        codigoFornecedor: this.codigoFornecedor,
        isForaUso: false,
        produtoDescricao: this.especificacoes,
        id: this.produtoId,
        instalacaoId: 0,
        produtoImagem: [
          {
            id: 0,
            isCapa: true,
            isMapa: false,
            legenda: "string",
            midiaUrl: this.capaUrl,
            tpMidia: "IMAGEM",
          },
        ],
        unidadeMedidaId: 1,
        planoId: this.editInfo.plano,
        situacao: type === 1 ? "Salvo" : "Cancelado",
        situacaoId: type,
        valor: this.precoVenda,
        valorDesconto: this.precoDesconto,
        valorTotal: this.precoTotal,
      };
      if (this.validated) {
        ProdutoService.salvar(payload)
          .then((response) => {
            console.log(response);
            this.confirmacaoProduto = false;
            this.$root.showSucesso("Operação realizada com sucesso");
            let a = 0;
            this.$emit("submit", a++);
          })
          .catch((error) => {
            if (error.status != 401) {
              this.$root.showErro(error.data);
            }
          })
          .finally(() => {
            this.loadingSalvar = false;
          });
      } else {
        this.$root.showErro("Preencha todos os campos obrigatórios");
      }
    },
    copiarDesconto() {
      const payload = {
        assuntoId: 0,
        categoriaId: this.editInfo.categoriaId,
        clienteId: this.editInfo.clienteId,
        descricao: this.nomeProduto,
        codigoFornecedor: this.codigoFornecedor,
        isForaUso: false,
        produtoDescricao: this.especificacoes,
        instalacaoId: 0,
        produtoImagem: [
          {
            id: 0,
            isCapa: true,
            isMapa: false,
            legenda: "string",
            midiaUrl: this.capaUrl,
            tpMidia: "IMAGEM",
          },
        ],
        unidadeMedidaId: 1,
        planoId: this.editInfo.plano,
        valor: this.precoVenda,
        valorDesconto: this.precoDesconto,
        valorTotal: this.precoTotal,
      };
      this.copyLoading = true;
      if (this.validated) {
        this.loadingSalvar = true;
        ProdutoService.copiarDesconto(this.produtoId, payload)
          .then((response) => {
            console.log(response);
            this.modalCopiarDesconto = false;
            this.$root.showSucesso("Operação realizada com sucesso");
            let a = 0;
            this.$emit("submit", a++);
          })
          .catch((error) => {
            if (error.status != 401) {
              this.$root.showErro(error.data);
            }
          })
          .finally(() => {
            this.modalCopiarDesconto = false;
            this.loadingSalvar = false;
            this.copyLoading = false;
          });
      } else {
        this.$root.showErro("Preencha todos os campos obrigatórios");
      }
    },
    submitForm() {
      if (this.isTipoPromocaoLimitado && this.quantity == null) {
        this.$root.showErro("Informe uma quantidade válida");
        return;
      }
      if (this.precoTotal - this.precoDesconto < 0) {
        this.$root.showErro("O valor de venda não pode ser inferior a zero");
        return;
      }
      const payload = {
        assuntoId: 0,
        categoriaId: this.categoria,
        clienteId: this.cliente,
        codigoFornecedor: this.codigoFornecedor,
        descricao: this.nomeProduto,
        isForaUso: false,
        produtoDescricao: this.especificacoes,
        id: this.produtoId,
        instalacaoId: 0,
        produtoImagem: [
          {
            id: 0,
            isCapa: true,
            isMapa: false,
            legenda: "string",
            midiaUrl: this.capaUrl,
            tpMidia: "IMAGEM",
          },
        ],
        unidadeMedidaId: this.unidadeMedida,
        planoId: 22,
        situacao: "Salvo",
        situacaoId: 1,
        valor: this.precoVenda,
        valorDesconto: this.precoDesconto,
        valorTotal: this.precoTotal,
      };
      if (this.validated) {
        ProdutoService.salvar(payload)
          .then((response) => {
            console.log(response);
            this.$root.showSucesso("Operação realizada com sucesso");
            this.$router.push("/listagem-produtos");
          })
          .catch((error) => {
            if (error.status != 401) {
              this.$root.showErro(error.data);
            }
          })
          .finally(() => {
            this.loadingSalvar = false;
          });
      } else {
        this.$root.showErro("Preencha todos os campos obrigatórios");
      }
    },
    getTipoPromocao() {
      ProdutoService.getTipoPromocao()
        .then((response) => {
          let promocaoTipo = response.data.map((p) => ({
            text: p.descricao,
            value: p.id,
          }));
          this.promocao = promocaoTipo.filter((p) => p.value != 1);
          if (this.categoriaPagamento == 3 && !this.codeUnico) {
            this.promocao = this.promocao.filter((f) => f.value == 3);
            this.promocao = 3;
          }
          if (this.categoriaPagamento == 2) {
            this.promocao = this.promocao.filter((f) => f.value !== 4);
          }
        })
        .catch((error) => {
          if (error.status != 401) {
            this.$root.showErro(error.data);
          }
        });
    },
    getAssuntos() {
      TiposService.getAllAssuntos()
        .then((response) => {
          this.assuntos = response.data.map((p) => ({
            text: p.descricao,
            value: p.id,
          }));
        })
        .catch((error) => {
          if (error.status != 401) {
            this.$root.showErro(error.data);
          }
        });
    },
    getMedida() {
      ProdutoService.getMedida()
        .then((response) => {
          this.medidas = response.data.map((m) => ({
            text: m.descricao,
            value: m.id,
          }));
        })
        .catch((error) => {
          if (error.status != 401) {
            this.$root.showErro(error.data);
          }
        })
        .finally(() => {
          this.loadingSalvar = false;
        });
    },
    handleUpload(type, event) {
      if (event && event.target && event.target.files.length > 0) {
        this.file = event.target.files[0];
        this.formData = new FormData();
        this.formData.append("file", this.file);
        this.formData.append("name", this.file.name);
        this.loadingMidia = true;
        ProdutoService.uploadMidia(this.formData)
          .then((response) => {
            this.capaUrl = response.data.midiaUrl;
            this.capaTpMidia = response.data.tpMidia;
            this.capaId = response.data.id;
          })
          .catch((erro) => {
            this.$root.showErro(erro.data);
          })
          .finally(() => {
            this.loadingMidia = false;
          });
      }
    },
  },
};
</script>

<style lang="scss">
.w-full {
  width: 100%;
}
.text-color-red {
  color: var(--v-primary-base);
}
.color-detail {
  color: #757575 !important;
}
</style>
