<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-transition"
    max-width="1000"
    persistent
  >
  <v-card>
    <v-toolbar style="margin-bottom: 5px;" flat>
      <v-toolbar-title>
        <span class="headline"> Adicionar Usuários para Checkin  </span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon large @click="handleClose()">mdi-close</v-icon>
    </v-toolbar>
    <v-divider />
    <v-row v-if="loading" class="justify-center height-body">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </v-row>
    <v-col cols="12">
        <v-col col='12'>
            <span class="text-subtitle-1" > Adicione os Usuários para habilitar o check-in</span>
        </v-col>
        <v-col col="12" class="mt-2 px-6 scroll-height">
            <v-row v-for="usuario in usuarios" :key="usuario.id" class="justify-space-between my-4 flex-wrap">
                <span>
                  {{ usuario.nome }}
                </span>
                <v-btn
                  fab
                  :disabled="situacao == 4 || situacao == 3 || Date.now() > finalDate"
                  color="primary"
                  x-small
                  class="mb-2"
                  @click="adicionarLista(usuario)"
                >
                <v-icon> mdi-plus </v-icon>
                </v-btn>
                <div class="w-full">
                  <v-divider/>
                </div>
            </v-row>
        </v-col>
        <v-col v-if="listaUsuarios">
          <span class="headline"> Usuários Autorizados </span>
        </v-col >
        <v-row v-if="loadingUsuarios" class="justify-center height-body">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-row>
        <div v-else>
          <v-chip
            v-for="usuario in listaUsuarios"
            :key="usuario.id"
            class="ma-2"
            close
            @click:close="removeFromList(usuario)"
          >
            {{ usuario.nome }}
          </v-chip>
        </div>
        <v-row class="justify-end mr-4 mb-4">
          <v-btn color="primary" @click="salvarUsuarios()" :disabled="usuarios == [] || situacao == 4 || situacao == 3 || Date.now() > finalDate">
            Salvar
          </v-btn>
        </v-row>
    </v-col>
  </v-card> 
  </v-dialog> 
</template>

<script>
import { ProdutoService } from '@/services';

export default {
  name: "moduloUsuarios",
  props: {
    id:{
      type: Number,
      default: null,
    },
    situacao: {
      type: Number,
      default: 0
    },
    finalDate: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      loading: false,
      dialog: true,
      usuarios: null,
      loadingUsuarios: false,
      listaUsuarios: []
    };
  },
  created(){
    this.getUsuarios()
    this.getUsuariosRegistrados()
  },
  watch: {
    listaUsuarios(){
      let idFilter = this.listaUsuarios.map(f => f.id)
      let usuariosArray = Object.values(this.usuarios)
      this.usuarios = usuariosArray.filter(u => !idFilter.includes(u.id))      
    },
  },
  methods: {
      handleClose(){
        this.$emit("close")
      },
      adicionarLista(user){
        this.listaUsuarios.push({id: user.id, nome: user.nome})
      },
      removeFromList(usuario){
        this.listaUsuarios = this.listaUsuarios.filter(e => e != usuario)
        this.usuarios.push({id: usuario.id, nome: usuario.nome})
      },
      getUsuarios(){
        this.loadingUsuarios = true
        ProdutoService.getUsuariosCheckin(this.id)
        .then((response) => {
          this.usuarios = response.data
        })
        .catch((error) => {
          if (error.status != 401) {
            this.$root.showErro(error.data);
          }
        })
        .finally(() => {
          this.loadingUsuarios = false;
        });
      },
      getUsuariosRegistrados(){
        this.loadingUsuarios = true
        ProdutoService.getUsuariosRegistrados(this.id)
        .then((response) => {
          this.listaUsuarios = response.data.map((usuario) => ({
            id: usuario.id,
            nome: usuario.nome,
          }));
        })
        .finally(() => {
          this.loadingUsuarios = false;
        });
      },
      salvarUsuarios(){
        let listaUsuariosFiltrada = this.listaUsuarios.map(e => e.id);
        let payload = {
          produtoId: this.id,
          usuarios: listaUsuariosFiltrada
        }
        this.loadingUsuarios = true
        ProdutoService.salvarUsuariosCheckin(payload)
        .then((response) => {
          this.$root.showSucesso(response.data);
          this.getUsuariosRegistrados();
        })
        .catch((error) => {
          if (error.status != 401) {
            this.$root.showErro(error.data);
          }
        })
        .finally(() => {
          this.loadingUsuarios = false;
        });
      },
  }

};
</script>

<style>
.w-mid{
  width: 50%
}
.scroll-height{
  max-height: 35vh;
  overflow-y: auto;
  scroll-behavior: smooth;
}

</style>