<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-transition"
    max-width="1500"
    persistent
  >
  <v-card>
    <v-toolbar flat>
      <v-toolbar-title>
        <span class="headline"> Adicionar Códigos  </span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon large @click="handleClose()">mdi-close</v-icon>
    </v-toolbar>
    <v-divider />
    <v-row v-if="loading" class="justify-center">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </v-row>
    <v-col cols="12">
        <v-col col='12'>
            <span class="text-subtitle-1" > Adicione os códigos fornecidos pelo parceiro para continuar </span>
        </v-col>
        <v-col col="12" class="mt-2">
            <v-row>
                <v-text-field
                  :disabled="situacao == 5 || situacao == 4 || situacao == 3"
                  v-model="code"
                  label="Código"
                  class="w-mid"
                />
                <v-btn
                  :disabled="situacao == 5 || situacao == 4 || situacao == 3 || Date.now() > finalDate"
                  fab
                  color="primary"
                  small
                  @click="adicionarLista()"
                >
                <v-icon> mdi-plus </v-icon>
                </v-btn>
            </v-row>
        </v-col>
        <v-col v-if="listCodes">
          <span class="headline"> Códigos </span>
        </v-col >
        <v-row v-if="loadingCodigos" class="justify-center height-body">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-row>
        <div v-else class="height-body">
          <v-chip
            v-for="obj in listCodes"
            :key="obj"
            class="ma-2"
            close
            @click:close="removeFromList(obj)"
          >
            {{ obj }}
          </v-chip>
        </div>
        <v-row class="justify-end mr-4 mb-4">
          <v-btn color="primary" :disabled="situacao == 5 || situacao == 4 || situacao == 3 || Date.now() > finalDate" @click="saveCodes()">
            Salvar
          </v-btn>
        </v-row>
    </v-col>
  </v-card> 
  </v-dialog> 
</template>

<script>
import { ProdutoService } from '@/services';

export default {
  name: "moduloCodigos",
  props: {
    id: {
      type: Number,
      default: null,
    },
    quantity: {
      type: Number,
      default: 0
    },
    situacao: {
      type: Number,
      default: 0
    },
    finalDate: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      loading: false,
      dialog: true,
      loadingCodigos: false,
      code: "",
      listCodes: [],
      toAddList: [],
      headers:[
        { text: 'Código', value: 'codes' },
      ]
    };
  },
  created(){
    this.getCodigos();
  },
  computed:{
    codigoNaoSalvo(code){
      const toAdd = this.toAddList.filter(e=> e === code)
      return toAdd ? true : false
    }
  },
  methods: {
      handleClose(){
        this.$emit("close")
      },
      adicionarLista(){
        if(!this.code || this.code.match(/^ *$/) !== null) {
          return this.$root.showErro("Digite um código válido")
        }
        this.toAddList.push(this.code)
        this.listCodes.push(this.code)
        this.code = ''
      },  
      removeFromList(code){
        let removed = this.listCodes.filter(e=> e !== code)
        this.listCodes = removed
      },
      saveCodes(){
        if(this.listCodes.length > this.quantity){
          return this.$root.showErro("A quantidade de códigos não pode ultrapassar a quantidade de cupons disponíveis");
        }
        let payload ={
          id: this.id,
          codigos: this.listCodes
        }
      this.loadingCodigos = true
      ProdutoService.salvarCodigos(payload)
        .then((response) => {
          this.$root.showSucesso(response.data);
          this.toAddList = []
        })
        .catch((error) => {
          if (error.status != 401) {
            this.$root.showErro(error.data);
          }
        })
        .finally(() => {
          this.loadingCodigos = false;
        });
      },
      getCodigos(){
        this.loadingCodigos = true
        ProdutoService.getCodigos(this.id)
          .then((response) => {
            this.listCodes = response.data
          })
          .catch((error) => {
            if (error.status != 401) {
              this.$root.showErro(error.data);
            }
          })
          .finally(() => {
            this.loadingCodigos = false;
          });
      }
      
  }

};
</script>

<style>
.w-mid{
  width: 50%
}
.height-body{
  height: 45vh
}
</style>